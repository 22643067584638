<template>
  <div class="login-container">
    <div class="col-12" style="text-align: center">
      <img
        v-if="logo"
        :src="logo"
        alt="Deface Tracker"
        class="sidebar-logo"
        style="width: 180px; margin: 150px 0px 0px 0px"
      >
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <h3 class="title" style="text-align: center">
        {{ $t("login.title") }}
      </h3>
      <lang-select class="set-language" />
      <el-form-item prop="email">
        <span class="svg-container">
          <i class="el-icon-user" />
        </span>
        <el-input
          v-model="loginForm.email"
          name="email"
          type="text"
          auto-complete="on"
          :placeholder="$t('login.emailOrUsername')"
        />
      </el-form-item>
      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock" />
        </span>
        <el-input
          v-model="loginForm.password"
          :type="pwdType"
          name="password"
          auto-complete="on"
          :placeholder="$t('login.password')"
          @keyup.enter.native="handleLogin"
        />
        <span class="show-pwd" @click="showPwd">
          <i class="el-icon-view" />
        </span>
      </el-form-item>
      <div class="post-login">
        <el-button
          :loading="loading"
          type="primary"
          style="width: 98%"
          @click.native.prevent="handleLogin"
        >
          {{ $t("login.title") }}
        </el-button>
      </div>
      <div class="tips" style="text-align: center">
        <router-link to="reset-password">
          <span> {{ $t("passwordreset.forgotPassword") }}</span>
        </router-link>
      </div>
    </el-form>
  </div>
</template>

<script>
import defaultSettings from '@/settings';
/* eslint-disable quotes */
import LangSelect from "@/components/LangSelect";
// import { validEmail } from '@/utils/validate';
import { csrf } from "@/api/auth";

export default {
  name: "Login",
  components: { LangSelect },
  data() {
    /* const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error('Please enter the correct email'));
      } else {
        callback();
      }
    };*/
    const validatePass = (rule, value, callback) => {
      if (value.length < 4) {
        callback(new Error(this.$t("login.shortPassword")));
      } else {
        callback();
      }
    };
    return {
      logo: defaultSettings.appbaseUrl.includes("beta")
        ? "/images/logo_white_beta.png"
        : "/images/logo_white.png",
      loginForm: {
        email: "",
        password: "",
      },
      loginRules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: this.$t("login.emailOrUsernameRequired"),
          },
        ], // ,validator: validateEmail
        password: [
          { required: true, trigger: "blur", validator: validatePass },
        ],
      },
      loading: false,
      pwdType: "password",
      redirect: undefined,
      otherQuery: {},
    };
  },
  watch: {
    $route: {
      // eslint-disable-next-line space-before-function-paren
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  methods: {
    showPwd() {
      if (this.pwdType === "password") {
        this.pwdType = "";
      } else {
        this.pwdType = "password";
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          csrf().then(() => {
            this.$store
              .dispatch("user/login", this.loginForm)
              .then(() => {
                this.$router.push(
                  { path: this.redirect || "/", query: this.otherQuery },
                  (onAbort) => {}
                );
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          });
        } else {
          this.$message({
            type: "danger",
            message: this.$t("general.invalidForm"),
          });
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    max-width: 100%;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    margin-top: 5px !important;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title {
    font-size: 26px;
    color: $light_gray;
    margin: 0px auto 10px auto;
    text-align: center;
    font-weight: bold;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .set-language {
    color: #fff;
    position: absolute;
    top: 40px;
    right: 35px;
  }
}
@media screen and (orientation: landscape) and (max-width: 1024px) {
  .login-container {
    position: relative;
    overflow-y: auto;
    .login-form {
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      margin: auto;
    }
  }
}
</style>
